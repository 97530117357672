<template>
  <div class="page-header">
    <nav class="navbar navbar-expand">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <header-left />
    </nav>
  </div>
</template>

<script>
import HeaderLeft from "./components/headerLeft.vue";
export default {
  components: { HeaderLeft },
};
</script>

<style>
</style>