<template>
  <div class="connect-container align-content-stretch d-flex flex-wrap">
    <div class="loader" v-show="isLoading">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      @click="
        $store.commit('SET_PDF_IS_SENDING', {
          isSending: false,
          isShow: false,
          text: 'Pdf has been sent with succesfully.',
        })
      "
      class="mail-action"
      v-if="getMailAction.isShow"
    >
      <Loading no-text v-if="getMailAction.isSending"></Loading>
      <div>{{ getMailAction.text }}</div>
      <div>x</div>
    </div>
    <side-bar />
    <div class="page-container">
      <Header />
      <div class="page-content mb-5">
        <breadcrumb class="d-none d-lg-block" />
        <div class="main-wrapper">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title d-none d-lg-block">{{ $route.name }}</h5>
              <router-view v-if="!isLoading" />
              <div v-if="!showNavbar" class="white-container d-lg-none"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{ 'd-none': !showNavbar, 'd-flex d-lg-none': showNavbar }"
        class="navigation-bar shadow"
      >
        <router-link
          exact-active-class="navigation-bar-active"
          class="navigation-bar-item"
          to="/home"
        >
          <i class="material-icons-outlined">home</i>
          <small>Home</small>
        </router-link>
        <router-link
          class="navigation-bar-item"
          active-class="navigation-bar-active"
          to="/create-report"
        >
          <i class="material-icons-outlined">add</i>
          <small>Create Report</small>
        </router-link>
        <img src="/images/logo.png" width="40" alt="Logo" />
        <router-link
          class="navigation-bar-item"
          active-class="navigation-bar-active"
          to="/products"
        >
          <i class="material-icons-outlined">inventory</i>
          <small>Products</small>
        </router-link>
        <router-link
          class="navigation-bar-item"
          exact-active-class="navigation-bar-active"
          to="/settings"
        >
          <i class="material-icons-outlined">settings</i>
          <small>Settings</small>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import sideBar from "@/components/shared/sidebar";
import Header from "@/components/shared/header";
import breadcrumb from "@/components/shared/breadcrumb";
import "vue-select/dist/vue-select.css";
import Loading from "../components/shared/loading/index.vue";

export default {
  components: { sideBar, Header, breadcrumb, Loading },
  data() {
    return {
      isLoading: true,
      showNavbar: true,
    };
  },
  computed: {
    getMailAction() {
      return this.$store.state.mailAction;
    },
  },
  async mounted() {
    const vm = this;
    document.addEventListener("focusin", function (e) {
      if (!e.srcElement.className.includes("navigation")) vm.showNavbar = false;
      else vm.showNavbar = true;
    });
    document.addEventListener("focusout", function () {
      vm.showNavbar = true;
    });

    this.$store.commit("SET_REPORT_FROM_LOCALSTORAGE");
    if (this.$api.token === "FAIL") {
      await this.$store.dispatch("GET_INITIAL_ACTIVE_USER");
      this.isLoading = false;
    } else this.isLoading = false;
  },
};
</script>

<style>
.float-button {
  position: fixed;
  z-index: 4;
  bottom: 10px;
  right: 10px;
}
.navigation-bar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: white;
  align-items: center;
  z-index: 10;
  justify-content: space-evenly;
}
.navigation-bar-active {
  color: #5fd0a5 !important;
  align-items: center;
  border-bottom: 4px solid #5fd0a5 !important;
  justify-content: center;
  display: flex;
}

.navigation-bar-item i {
  font-size: 24px;
}
.navigation-bar-item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  color: #2b5b48;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mail-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 70px;
  padding: 10px;
  width: 100%;
  left: 0;
  background: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-width: 300px;
  animation: slideIn 0.5s ease-in;
}
@keyframes slideIn {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}
</style>