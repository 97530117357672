<template>
  <div class="page-sidebar-inner slimscroll">
    <ul class="accordion-menu">
      <li class="sidebar-title">Apps</li>
      <li
        @click="$eralp.ToggleButton('small-screen-sidebar-active')"
        :class="{ 'active-page': $route.path === nav.path }"
        v-for="(nav, i) in sidebar"
        :key="i"
      >
        <router-link :to="nav.path" exact-active-class="active"
          ><i class="material-icons-outlined">{{ nav.icon }}</i
          >{{ nav.name }}</router-link
        >
      </li>
      <li @click="SET_LOGOUT">
        <router-link to="/sign-in" exact-active-class="active">
          <i class="material-icons-outlined">logout</i> Sign Out
        </router-link>
      </li>
    </ul>
    <div class="mt-4 text-center">
      <small class="text-muted"
        >Copyright ©
        <a target="_blank" href="https://eralpsoftware.com"> Eralp Software.com</a></small
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    sidebar() {
      return this.$router.options.routes[0].children.filter((c) => c.isSidebar);
    },
  },
  methods: {
    SET_LOGOUT() {
      localStorage.removeItem("user");
      localStorage.removeItem("report");
      this.$api.token = "";
    },
  },
};
</script>

<style>
</style>