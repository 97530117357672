<template>
  <div class="page-sidebar">
    <logo-box />
    <side-bar />
  </div>
</template>

<script>
import LogoBox from "./components/logoBox.vue";
import SideBar from "./components/sideBar.vue";
export default {
  components: { LogoBox, SideBar },
};
</script>

<style>
</style>