<template>
  <ul class="navbar-nav mobile-nav">
    <li class="nav-item small-screens-sidebar-link">
      <a
        @click="$eralp.ToggleButton('small-screen-sidebar-active')"
        href="javascript:void(0)"
        class="nav-link"
        ><i class="material-icons-outlined">menu</i></a
      >
    </li>
    <li class="d-none d-lg-inline nav-item nav-profile dropdown">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="/images/logo.png" alt="profile image" />
        <span>{{ getActiveUser.fullname }}</span
        ><i class="material-icons dropdown-icon">keyboard_arrow_down</i>
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#"
          >Calendar<span class="badge badge-pill badge-info float-right"
            >2</span
          ></a
        >
        <a class="dropdown-item" href="#">Settings & Privacy</a>
        <a class="dropdown-item" href="#">Switch Account</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Log out</a>
      </div>
    </li>
    <li class="nav-item">
      <a
        @click="$eralp.ToggleButton('dark-theme')"
        href="#"
        class="nav-link"
        id="dark-theme-toggle"
        ><i class="material-icons-outlined">brightness_2</i
        ><i class="material-icons">brightness_2</i></a
      >
    </li>
    <li class="nav-item d-inline d-lg-none" style="width: 200px">
      <a
        href="javascript:void(0)"
        class="nav-link"
        style="
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        "
        >{{ $route.name }}</a
      >
    </li>
    <li class="nav-item d-inline d-lg-none">
      <a
        @click="$router.go(-1)"
        href="javascript:void(0)"
        class="nav-link"
        style="font-size: 16px"
        ><i class="material-icons-outlined">arrow_back</i></a
      >
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    getActiveUser() {
      return this.$store.state.user.activeUser;
    },
  },
  methods: {
    SET_LOGOUT() {
      localStorage.removeItem("user");
      localStorage.removeItem("report");
      this.$api.token = "";
    },
  },
};
</script>

<style>
.mobile-nav {
  flex-direction: row-reverse !important;
  width: 100%;
  justify-content: space-between;
}
</style>