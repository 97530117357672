<template>
  <div class="text-center">
    <span class="spinner-border mr-2" role="status" aria-hidden="true"></span>
    <p v-if="!noText">Loading...</p>
  </div>
</template>

<script>
export default {
  props: {
    noText: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style>
</style>