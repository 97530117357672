<template>
  <div class="page-info">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          :class="{ active: $route.name === r.name }"
          v-for="(r, i) in $route.matched"
          :key="i"
        >
          <router-link v-if="$route.name !== r.name" :to="r.path">{{
            r.name
          }}</router-link>
          <span v-else>{{ r.name }}</span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>