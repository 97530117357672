<template>
  <div class="logo-box">
    <a href="#" class="logo-text"> <img src="/images/logo.png" width="50%" alt="" /></a
    ><a
      @click="$eralp.ToggleButton('small-screen-sidebar-active')"
      href="javascript:void(0)"
      id="sidebar-close"
      ><i class="material-icons">close</i></a
    >
    <a
      href="#"
      @click="$eralp.ToggleButton('compact-sidebar')"
      id="sidebar-state"
      ><i class="material-icons">adjust</i
      ><i class="material-icons compact-sidebar-icon">panorama_fish_eye</i></a
    >
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>